// Sidebar.tsx (React Component)
import React, { useState } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import '../../App.css';

const Sidebar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openNext, setOpenNext] = useState<boolean>(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="burger-menu" onClick={toggleSidebar}>
        ☰
      </div>
      <div className="close-button" onClick={toggleSidebar}>
        ✕
      </div>
      {isOpen && (
        <div className='sidebar-content'>
          <button className="menu-item-sidebar" onClick={() => {
            setOpenNext(!openNext);
          }} >
            <Link to={"/listmanager"} className="menu-item-sidebar">
              Listmanager
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/leadgenpartner"} className="menu-item-sidebar">
              Lead Gen Partner
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/uploadcenter"} className="menu-item-sidebar">
              Upload Center
            </Link>
          </button>


          <button className="menu-item-sidebar" onClick={() => setOpen(!open)}>
            <Link to={"/optinsearch"} className="menu-item-sidebar">
              OPT-IN Search
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/selectionexport"} className="menu-item-sidebar">
              Selection and export Center/Manager
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/unsubscribecontact"} className="menu-item-sidebar">
              Unsubscribe/Contact Center
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/usermanager"} className="menu-item-sidebar">
              User manager and Log-Center
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/templatecenter"} className="menu-item-sidebar">
              Template Center
            </Link>
          </button>

          <button className="menu-item-sidebar">
            <Link to={"/dashboards"} className="menu-item-sidebar">
              Dashboards
            </Link>
          </button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
