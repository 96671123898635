import React, { useState, useEffect } from "react";
import { getUserBoard } from "../../services/user.service";
import { RouteComponentProps, Link } from "react-router-dom";
import RouterProps from "../../types/router.type";

type Props = RouteComponentProps<RouterProps>;

const BoardUser: React.FC<Props> = ({ history }) => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    getUserBoard().then(
      (response: any) => {
        setContent(response.data.username);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          if(_content === "Unauthorized") {
            localStorage.removeItem("user");
            history.push("/login");
            window.location.reload();
          }

        setContent(_content);
      }
    );
  }, [history]);
  //#212529

  return (
    <div style={{padding: 20, borderRadius: 0}}>
      <h3 className="login-header">Opt-in Info</h3>
      <div className="form-group" style={{ textAlign: 'center' }}>
        This application helps to process opt-in data
      </div>
      <div className="form-group">
        <Link to={"/listmanager"} className="menu-item info-item">
          1. Listmanager
        </Link>
        <div>
          In the menu List Manager you can create,
          update and delete email lists (if you have the rights for this),
          you have to upload a picture in a corresponding resolution,
          as well as create all data for the imprint.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/leadgenpartner"} className="menu-item info-item">
          2. Lead Gen Partner
        </Link>
        <div>
          Here you create a lead-gen partner,
          each data record that is uploaded later must be assigned to a lead-gen partner.
          Furthermore, you can add screenshots of sweepstakes,
          DOI mail, etc. when creating each Lead-Gen partner.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/uploadcenter"} className="menu-item info-item">
          3. Upload Center
        </Link>
        <div>
          Here data can be uploaded to a list,
          these data are then automatically uploaded via API
          also in the corresponding mailing tools such as Kajomi and Maileon.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/optinsearch"} className="menu-item info-item">
          4. OPT-IN Search
        </Link>
        <div>
          First you choose a list,
          then you can select a data set by e-mail and download an optin proof (PDF) to it,
          which contains all data of the contact in the list inclusive.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/selectionexport"} className="menu-item info-item">
          5. Selection and export Center/Manager
        </Link>
        <div>
          In this point you should be able to select by individual lists
          or in total over all lists, by gender, age, street, zip code, city,
          purchasing power person, purchasing power household, household size, etc.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/unsubscribecontact"} className="menu-item info-item">
          6. Unsubscribe/Contact Center
        </Link>
        <div>
          In this function you can unsubscribe users individually
          or in bulk via upload or multiple form, blacklist.
          Per API these users are blacklisted or unsubscribed in the mailingtools.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/usermanager"} className="menu-item info-item">
          7. User manager and Log-Center
        </Link>
        <div>
          Here users can be created, activated and deactivated,
          for each user there is a unique event log, what he has done in this tool.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/templatecenter"} className="menu-item info-item">
          8. Template Center
        </Link>
        <div>
          Here, various templates are to be created, which can then be downloaded later from the individual lists
          as header/footer templates with the corresponding data and images.
        </div>
      </div>

      <div className="form-group">
        <Link to={"/dashboards"} className="menu-item info-item">
          9. Dashboards
        </Link>
        <div>
          Here is a general dashboard over all data, as well as for the individual lists.
        </div>
      </div>
    </div>
  );
};

export default BoardUser;