import React, { useState, useEffect, ChangeEvent } from "react";
import { getUserBoard } from "../../services/user.service";
import { RouteComponentProps } from "react-router-dom";
import SideBar from "../FunctionalComponents/Sidebar";
import RouterProps from "../../types/router.type";

type Props = RouteComponentProps<RouterProps>;

const ListManager: React.FC<Props> = ({ history }) => {
    const [content, setContent] = useState<string>("");
    
    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    history.push("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, [history]);

    return (
        <div className="for-height">
            <div className="col-md-12 col-12-new">
                <div className="card card-container2 new-card">
                    <h3 className="login-header">Listmanager</h3>
                    <div>
                        In the menu List Manager you can create, 
                        update and delete email lists (if you have the rights for this), 
                        you have to upload a picture in a corresponding resolution, 
                        as well as create all data for the imprint.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListManager;