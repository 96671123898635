import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";

import FormikReactSelect from "../FunctionalComponents/FormikReactSelect";
import UploadFile from "../../types/file.type";
import { getUserBoard } from "../../services/user.service";
import { getUsersData } from "../../services/users-data.service";
import { RouteComponentProps } from "react-router-dom";
import SideBar from "../FunctionalComponents/Sidebar";
import Papa from "papaparse";
import { formateDateTimeLocale } from "../../utils/formateDate";
import RouterProps from "../../types/router.type";
import { Table } from "../FunctionalComponents/Table";

type Props = RouteComponentProps<RouterProps>;

const ExportData: React.FC<Props> = ({ history }) => {
    const [content, setContent] = useState<string>("");
    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [fieldValue, setFieldValue] = useState<UploadFile[]>([]);
    const [useColumns, setUseColumns] = useState<any>([]);
    const [useName, setUseName] = useState<boolean>(false);
    const [useTitle, setUseTitle] = useState<boolean>(false);
    const [useDate, setUseDate] = useState<boolean>(false);
    const [useEmail, setUseEmail] = useState<boolean>(false);
    const [useZip, setUseZip] = useState<boolean>(false);
    const [useCity, setUseCity] = useState<boolean>(false);
    const [useImport, setUseImport] = useState<boolean>(true);
    const getFileName = () => {
        return formateDateTimeLocale(new Date()) + "_optin-data.csv";
    };

    const columns = useMemo(
        () => [
          {
            Header: "Users data",
            columns: [
              {
                Header: "Email",
                accessor: "emailaddress"
              },
              {
                Header: "First Name",
                accessor: "firstname"
              },
              {
                Header: "Last Name",
                accessor: "lastname"
              },
              {
                Header: "Birth date",
                accessor: "birthdate"
              },
              {
                Header: "Title",
                accessor: "title"
              },
              {
                Header: "Zip code",
                accessor: "zipcode"
              },
              {
                Header: "City",
                accessor: "city"
              }
            ]
          }
        ],
        []
      );

    useEffect(() => {
        getUserBoard().then(
            (response: any) => {
                setContent(response.data.username);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (_content === "Unauthorized") {
                    localStorage.removeItem("user");
                    history.push("/login");
                    window.location.reload();
                }

                setContent(_content);
            }
        );
    }, [history]);

    const initialValues: {
        file: any,
        selectOptions: any;
    } = {
        file: [],
        selectOptions: [],
    };

    const validationSchema = Yup.object().shape({
        // email: Yup.string()
        //     .email("This is not a valid email.")
        //     .required("This field is required!")
    });

    const onChangeImportData = async () => {
        getUsersData().then(
            (response: any) => {
                setUseColumns(columns);
                setFieldValue(response.data);
                setUseImport(false);
                console.log(response.data,'Success Imported>>>');
            },
            (error) => {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }

    const handleImport = (formValue: {}, { resetForm }: FormikHelpers<any>) => {
        let newArr: any = [];
        if (fieldValue.length < 1) return;
        fieldValue.map((item: any) => {
            item = {
                ...(useDate && { Geburtsdatum: item.birthdate }),
                ...(useName && { firstname: item.firstname, lastname: item.lastname }),
                ...(useTitle && { title: item.title }),
                ...(useEmail && { emailaddress: item.emailaddress }),
                ...(useZip && { zipcode: item.zipcode}),
                ...(useCity && { city: item.city })
            }
            newArr.push(item);
        })
        const csv = Papa.unparse(newArr, { delimiter: ";" });
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", getFileName());
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setUseName(false);
        setUseTitle(false);
        setUseDate(false);
        setMessage('message');
        setSuccessful(true);
        resetForm();
    };

    const options = [
        { value: "name", label: "Name" },
        { value: "title", label: "Title" },
        { value: "date", label: "Date of birth" },
        { value: "zipcode", label: "Zip code" },
        { value: "city", label: "City" },
        { value: "email", label: "Email" }
    ];
    return (
        <div className="for-height">
            <SideBar />
            <div className="col-md-12">
                <div className="card card-container2" style={{ marginTop: 0, marginLeft: 250 }}>
                    <h3 className="login-header">Export data</h3>
                    {(fieldValue && useColumns) && (
                        <div className="form-group table-init">
                            <Table columns={useColumns} data={fieldValue} />
                        </div>
                    )}
                    {useImport && (
                        <button 
                            onClick={onChangeImportData}
                            className="btn btn-submit btn-block"
                        >
                            Import data from DB
                        </button>
                    )}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleImport}
                    >
                        <Form>
                            <div className="form-group">
                                <label htmlFor="convertdata"> Select headers </label>
                                <FormikReactSelect
                                    name="selectOptions"
                                    isMulti={true}
                                    options={options}
                                    setUseName={setUseName}
                                    setUseTitle={setUseTitle}
                                    setUseDate={setUseDate}
                                    setUseEmail={setUseEmail}
                                    setUseZip={setUseZip}
                                    setUseCity={setUseCity}
                                />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-submit btn-block">Generate CSV</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default ExportData;