import React, { useState } from 'react';
import { unsubscribeContact } from '../../services/unsubscribe.service';

const UnsubscribeForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [reason, setReason] = useState<string>('too_many_messages');
    const [message, setMessage] = useState<any>(null);
    const [error, setError] = useState<string>('');

    const handleUnsubscribe = async () => {
        try {
            const response = await unsubscribeContact(email, reason);
            if (typeof response === 'string') {
                setError(response);
                // setMessage(response);
            } else {
                setMessage(response);
                setError('');
            }
        } catch (error) {
            setMessage('Failed to unsubscribe');
        }
    };

    return (
        <div>
            <h3>Unsubscribe Contact</h3>
            <div>
                <label>Email:</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
                <label>Reason:</label>
                <select value={reason} onChange={(e) => setReason(e.target.value)}>
                    <option value="too_many_messages">Too Many Messages</option>
                    <option value="no_longer_interested">No Longer Interested</option>
                    {/* Add other reasons as needed */}
                </select>
            </div>
            <button onClick={handleUnsubscribe}>Unsubscribe</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default UnsubscribeForm;
