import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getUserBoard } from "../../services/user.service";
import { MapContainer, TileLayer } from "react-leaflet";
import { FcConferenceCall, FcBusinessman, FcBusinesswoman, FcDecision, FcAbout } from 'react-icons/fc';
import { processData } from "../../utils/dashboardUtils";
import { getAverageAge } from "../../utils/getAverageAge";
import { getAverageName } from "../../utils/getAverageName";
import RouterProps from "../../types/router.type";
import { defineDecPlaces } from '../../utils/userDataUtils';
import { renderInfoBlock, renderAverageContainer } from '../FunctionalComponents/DashboardTools';

type Props = RouteComponentProps<RouterProps>;

const Dashboards: React.FC<Props> = ({ history }) => {
    const [content, setContent] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [male, setMale] = useState<number>(0);
    const [female, setFemale] = useState<number>(0);
    const [diverse, setDiverse] = useState<number>(0);
    const [maleAge, setMaleAge] = useState<number>(0);
    const [femaleAge, setFemaleAge] = useState<number>(0);
    const [diverseAge, setDiverseAge] = useState<number>(0);
    const [personAge, setPersonAge] = useState<number>(0);
    const [maleName, setMaleName] = useState<string>("");
    const [femaleName, setFemaleName] = useState<string>("");
    const [diverseName, setDiverseName] = useState<string>("");
    const [personCity, setPersonCity] = useState<string>("");
    const [maleCity, setMaleCity] = useState<string>("");
    const [femaleCity, setFemaleCity] = useState<string>("");
    const [diverseCity, setDiverseCity] = useState<string>("");
    const [personPPI, setPersonPPI] = useState<number>(0);
    const [malePPI, setMalePPI] = useState<number>(0);
    const [femalePPI, setFemalePPI] = useState<number>(0);
    const [diversePPI, setDiversePPI] = useState<number>(0);
    const [personPPM, setPersonPPM] = useState<number>(0);
    const [malePPM, setMalePPM] = useState<number>(0);
    const [femalePPM, setFemalePPM] = useState<number>(0);
    const [diversePPM, setDiversePPM] = useState<number>(0);
    const [personPPY, setPersonPPY] = useState<number>(0);
    const [malePPY, setMalePPY] = useState<number>(0);
    const [femalePPY, setFemalePPY] = useState<number>(0);
    const [diversePPY, setDiversePPY] = useState<number>(0);
    const [personPPHM, setPersonPPHM] = useState<number>(0);
    const [malePPHM, setMalePPHM] = useState<number>(0);
    const [femalePPHM, setFemalePPHM] = useState<number>(0);
    const [diversePPHM, setDiversePPHM] = useState<number>(0);
    const [personPPHY, setPersonPPHY] = useState<number>(0);
    const [malePPHY, setMalePPHY] = useState<number>(0);
    const [femalePPHY, setFemalePPHY] = useState<number>(0);
    const [diversePPHY, setDiversePPHY] = useState<number>(0);
    const [personHS, setPersonHS] = useState<number>(0);
    const [maleHS, setMaleHS] = useState<number>(0);
    const [femaleHS, setFemaleHS] = useState<number>(0);
    const [diverseHS, setDiverseHS] = useState<number>(0);

    const COPY_URL = process.env.REACT_APP_COPY_URL as string;
    const TILE_URL = process.env.REACT_APP_TILE_URL as string;

    useEffect(() => {
        getUserBoard().then(
            async (responses: any) => {
                setContent(responses.data.username);
                try {
                    const {
                        users,
                        countMale,
                        countFemale,
                        countDiverse,
                        ageArray,
                        ageMaleArray,
                        ageFemaleArray,
                        ageDiverseArray,
                        nameMaleArray,
                        nameFemaleArray,
                        nameDiverseArray,
                        purchasingPowerData
                    } = await processData();

                    setTotal(users.length);
                    setMale(countMale);
                    setFemale(countFemale);
                    setDiverse(countDiverse);

                    setPersonAge(getAverageAge(ageArray));
                    setMaleAge(getAverageAge(ageMaleArray));
                    setFemaleAge(getAverageAge(ageFemaleArray));
                    setDiverseAge(getAverageAge(ageDiverseArray));

                    setMaleName(getAverageName(nameMaleArray));
                    setFemaleName(getAverageName(nameFemaleArray));
                    setDiverseName(getAverageName(nameDiverseArray));

                    setPersonPPI(defineDecPlaces(purchasingPowerData.averageIndexPowerPerson));
                    setPersonPPM(defineDecPlaces(purchasingPowerData.averagePurPowerPersonMonth));
                    setPersonPPY(defineDecPlaces(purchasingPowerData.averagePurPowerPerson));
                    setPersonPPHM(defineDecPlaces(purchasingPowerData.averagePurPowerHouseMonth));
                    setPersonPPHY(defineDecPlaces(purchasingPowerData.averagePurPowerHouse));
                    setPersonHS(defineDecPlaces(purchasingPowerData.averagePopulationHousehold));
                    setPersonCity(purchasingPowerData.largestCity || '');

                    setMalePPI(defineDecPlaces(purchasingPowerData.averageIndexPowerPersonMale));
                    setMalePPM(defineDecPlaces(purchasingPowerData.averagePurPowerPersonMaleMonth));
                    setMalePPY(defineDecPlaces(purchasingPowerData.averagePurPowerPersonMale));
                    setMalePPHM(defineDecPlaces(purchasingPowerData.averagePurPowerHouseMaleMonth));
                    setMalePPHY(defineDecPlaces(purchasingPowerData.averagePurPowerHouseMale));
                    setMaleHS(defineDecPlaces(purchasingPowerData.averagePopulationHouseholdMale));
                    setMaleCity(purchasingPowerData.largestCityMale || '');

                    setFemalePPI(defineDecPlaces(purchasingPowerData.averageIndexPowerPersonFemale));
                    setFemalePPM(defineDecPlaces(purchasingPowerData.averagePurPowerPersonFemaleMonth));
                    setFemalePPY(defineDecPlaces(purchasingPowerData.averagePurPowerPersonFemale));
                    setFemalePPHM(defineDecPlaces(purchasingPowerData.averagePurPowerHouseFemaleMonth));
                    setFemalePPHY(defineDecPlaces(purchasingPowerData.averagePurPowerHouseFemale));
                    setFemaleHS(defineDecPlaces(purchasingPowerData.averagePopulationHouseholdFemale));
                    setFemaleCity(purchasingPowerData.largestCityFemale || '');

                    setDiversePPI(defineDecPlaces(purchasingPowerData.averageIndexPowerPersonOther));
                    setDiversePPM(defineDecPlaces(purchasingPowerData.averagePurPowerPersonOtherMonth));
                    setDiversePPY(defineDecPlaces(purchasingPowerData.averagePurPowerPersonOther));
                    setDiversePPHM(defineDecPlaces(purchasingPowerData.averagePurPowerHouseOtherMonth));
                    setDiversePPHY(defineDecPlaces(purchasingPowerData.averagePurPowerHouseOther));
                    setDiverseHS(defineDecPlaces(purchasingPowerData.averagePopulationHouseholdOther));
                    setDiverseCity(purchasingPowerData.largestCityOther || '');

                } catch (error: any) {
                    console.error("Error fetching user data:", error.message);
                }
            },
            (error) => {
                console.error("Error fetching user board:", error.message);
                if (error.response?.data?.message === "Unauthorized") {
                    localStorage.removeItem("user");
                    history.push("/login");
                    window.location.reload();
                }
            }
        );
    }, [history]);

    return (
        <div className="for-height" style={{ borderRadius: 0 }}>
            <div className="col-md-12 col-12-new">
                <div className="card card-container2 new-card">
                    <h3 className="login-header">Dashboards</h3>
                    <h4 style={{ color: '#788491' }}>Gender info</h4>
                    <div className="hr-box">
                        {renderInfoBlock(<FcConferenceCall className="hr-icon" />, "Total", total)}
                        {renderInfoBlock(<FcBusinessman className="hr-icon" />, "Male", male)}
                        {renderInfoBlock(<FcBusinesswoman className="hr-icon" />, "Female", female)}
                        {renderInfoBlock(<FcDecision className="hr-icon" />, "Diverse", diverse)}
                    </div>
                    <br />
                    <h4 style={{ color: '#788491' }}>Average Person info</h4>
                    <div className="heatmap-block">
                        {renderAverageContainer("Average Person", <FcConferenceCall className="hr-icon-average" />, personAge, personPPI, personPPM, personPPY, personPPHM, personPPHY, personHS, personCity)}
                        {renderAverageContainer("Average Male", <FcBusinessman className="hr-icon-average" />, maleAge, malePPI, malePPM, malePPY, malePPHM, malePPHY, maleHS, maleCity, maleName)}
                        {renderAverageContainer("Average Female", <FcBusinesswoman className="hr-icon-average" />, femaleAge, femalePPI, femalePPM, femalePPY, femalePPHM, femalePPHY, femaleHS, femaleCity, femaleName)}
                        {renderAverageContainer("Average Diverse", <FcDecision className="hr-icon-average" />, diverseAge, diversePPI, diversePPM, diversePPY, diversePPHM, diversePPHY, diverseHS, diverseCity, diverseName)}
                    </div>
                    <br />
                    <h4 style={{ color: '#788491' }}>Heatmap info</h4>
                    <div className="heatmap-block">
                        <div className="heatmap-container">
                            <h4 className="heatmap-title">Map of Registered User</h4>
                            <div id="firstMap" className="heatmap-item">
                                <MapContainer
                                    center={[51.1657, 10.4515]}
                                    zoom={5} scrollWheelZoom={true}
                                    zoomControl={false}
                                    attributionControl={false}
                                >
                                    <TileLayer
                                        attribution={COPY_URL}
                                        url={TILE_URL}
                                    />
                                </MapContainer>
                            </div>
                        </div>
                        <div className="heatmap-container">
                            <h4 className="heatmap-title">Map of Registered Users By Zip</h4>
                            <div id="secondMap" className="heatmap-item">
                                <MapContainer
                                    center={[51.1657, 10.4515]}
                                    zoom={5} scrollWheelZoom={true}
                                    zoomControl={false}
                                    attributionControl={false}
                                >
                                    <TileLayer
                                        attribution={COPY_URL}
                                        url={TILE_URL}
                                    />
                                </MapContainer>
                            </div>
                        </div>
                    </div>
                    <br />
                    <h4 style={{ color: '#788491' }}>Next info</h4>
                    <div className="hr-box">
                        {renderInfoBlock(<FcAbout className="hr-icon" />, "Next", 0)}
                        {renderInfoBlock(<FcAbout className="hr-icon" />, "Next", 0)}
                        {renderInfoBlock(<FcAbout className="hr-icon" />, "Next", 0)}
                        {renderInfoBlock(<FcAbout className="hr-icon" />, "Next", 0)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboards;
