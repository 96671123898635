import React from "react";
import { useState, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import picture from './reachad-black.jpeg';

import * as AuthService from "./services/auth.service";
import IUser from './types/user.type';

import Login from "./components/Pages/Login";
import Register from "./components/Pages/Register";
import Home from "./components/Pages/Home";
import NotFound from "./components/Pages/NotFound";
import Profile from "./components/Pages/Profile";
import BoardUser from "./components/Boards/BoardUser";
import ExportData from "./components/OldComponents/ExportData";
import ImportData from "./components/OldComponents/ImportData";
import ConvertData from "./components/OldComponents/ConvertData";
import SaveData from "./components/OldComponents/SaveData";
import Dashboards from "./components/OptComponents/Dashboards";
import LeadGenPartner from "./components/OptComponents/LeadGenPartner";
import Listmanager from "./components/OptComponents/Listmanager";
import OptInSearch from "./components/OptComponents/OptInSearch";
import SelectionExport from "./components/OptComponents/SelectionExport";
import TemplateCenter from "./components/OptComponents/TemplateCenter";
import UnsubscribeContact from "./components/OptComponents/UnsubscribeContact";
import UploadCenter from "./components/OptComponents/UploadCenter";
import UserManager from "./components/OptComponents/UserManager";
import BoardModerator from "./components/Boards/BoardModerator";
import BoardAdmin from "./components/Boards/BoardAdmin";
import SideBar from "./components/FunctionalComponents/Sidebar";

import EventBus from "./common/EventBus";

const App: React.FC = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState<boolean>(false);
  const [showAdminBoard, setShowAdminBoard] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowAdminBoard(user.username.includes("admin"));
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <div className="main-container">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={{ pathname: "https://reachad.de/" }} target="_blank" >
          <img src={picture} className="reachad-logo" />
        </Link>
        <Link to={"/all"} className="navbar-brand">
          Opt-in Center
        </Link>
        <div className="navbar-nav mr-auto">
          {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/info"} className="nav-link">
                Info
              </Link>
            </li>
          )}
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3 main-header container-flex">
        <div className="app-container">
          {currentUser && (
            <SideBar />
          )}
          <div className="content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path={["/all"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/info" component={BoardUser} />
              <Route path="/exportdata" component={ExportData} />
              <Route path="/importdata" component={ImportData} />
              <Route path="/convertdata" component={ConvertData} />
              <Route path="/savedata" component={SaveData} />
              <Route path="/listmanager" component={Listmanager} />
              <Route path="/leadgenpartner" component={LeadGenPartner} />
              <Route path="/uploadcenter" component={UploadCenter} />
              <Route path="/optinsearch" component={OptInSearch} />
              <Route path="/selectionexport" component={SelectionExport} />
              <Route path="/unsubscribecontact" component={UnsubscribeContact} />
              <Route path="/usermanager" component={UserManager} />
              <Route path="/templatecenter" component={TemplateCenter} />
              <Route path="/dashboards" component={Dashboards} />
              <Route path="/mod" component={BoardModerator} />
              <Route path="/admin" component={BoardAdmin} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;