import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const unsubscribeContact = async (email: string, reason: string) => {
    try {
        const { status } = await axios.delete(`${API_URL}contacts/email/${email}/unsubscribe?reason=${encodeURIComponent(reason)}`);
        if (status === 200) {
            console.log('Unsubscribed successfully');
        } else {
            console.log('Failed to unsubscribe');
        }
    } catch (error: any) {
        console.error('Error unsubscribing contact:', error.response.data.message);
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        } else {
            return 'Error unsubscribing contact:';
        }
    }
};
